import React from 'react'
import './css/inputs.css'
import Fields from './fields.jsx'
import Calculate from '../buttons/calculate'
import TotalResults from '../totalResults'
import Enlarged from '../geometry/enlarged'
import { useCalculations } from '../../state/calculationsContext'
import { useGlobal } from '../../state/context'

const Inputs = () => {
  const calcState = useCalculations();
  const state = useGlobal();
  let display = !state.boolean ? 'd-none' : 'd-block';
  return (
    <section className="content-container inputs-section justify-content-between d-flex">
      <div className="col-12 col-md-6 col-lg-4">
        <h2 className="section-title">いくら必要ですか？</h2>
        <h4 className="mono-type mono-type__dimensions">寸法を入力</h4>
        <Fields />
        <Calculate />
      </div>
      <div className={`col-12 col-md-6 col-lg-8 ${display}`}>
        <Enlarged />
      </div>
      <div className={`col-12 col-md-6 col-lg-8 ${calcState.showResults}`}>
        <TotalResults />
      </div>
    </section>
  )
}

export default Inputs