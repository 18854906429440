import React from 'react'
import { useCalculations } from '../../state/calculationsContext'

const Quantity = () => {
    const state = useCalculations();
    return (
        <>
            <label className="input-label--unfocused">数量</label>
            <input type="number" value={state.inputValues.quantity} className="input-field input" id="quantity" onChange={state.handleQuantity} />
        </>
    )
}

export default Quantity