import React from 'react';
import Input from './input.jsx';
import Units from './units'
import Quantity from './quantity'

const Stair = () => {
    return (
        <>
            <Units />
            <label>踏み面</label>
            <Input
                parameter={"run"}
            />
            <label>蹴上</label>
            <Input
                parameter={"rise"}
            />
            <label>幅</label>
            <Input
                parameter={"width"}
            />
            <label>踊り場奥行</label>
            <Input
                parameter={"platformDepth"}
            />
            <label>number of steps</label>
            <Input
                parameter={"numSteps"}
            />
            <Quantity />
        </>
    )
}

export default Stair