import React from 'react';
import Input from './input.jsx';
import Units from './units'
import Quantity from './quantity'

const Tube = () => {
    return (
        <>
            <Units />
            <label>外径</label>
            <Input
                parameter={"outerDepth"}
            />
            <label>内径</label>
            <Input
                parameter={"innerDepth"}
            />
            <label>高さ</label>
            <Input
                parameter={"height"}
            />
            <Quantity />
        </>
    )
}

export default Tube