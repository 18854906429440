import React from 'react';
import Input from './input.jsx';
import Quantity from './quantity'
import Units from './units'

const Slab = () => {
	return (
		<>
			<Units />
			<label>奥行き</label>
			<Input
				parameter={"length"}
			/>
			<label>幅</label>
			<Input
				parameter={"width"}
			/>
			<label>高さ</label>
			<Input
				parameter={"height"}
			/>
			<Quantity />

		</>
	)
}

export default Slab