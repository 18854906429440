import React from 'react'
import { useCalculations } from '../state/calculationsContext'
import Results from './results'
import Error from './error'

const TotalResults = () => {
  const state = useCalculations();

  switch (state.displayError) {
    case 'true':
      return <Error />
    case 'false':
      return <Results />
  }
}

export default TotalResults