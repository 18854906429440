import React from 'react'
import './css/categories.css'
import Button from '../buttons/Button'
import { useGlobal } from '../../state/context'

const Categories = () => {

  const state = useGlobal();

  return (
    <div className='categories-container d-flex justify-content-start'>
      {
        Object.entries(state.options).map(([key, value]) =>
          <Button
            buttonClass={value.button}
            handleSelect={state.handleSelect}
            optionName={value.name}
            optionImg={value.img}
            alt={value}
          />
        )
      }
    </div>
  )
}

export default Categories