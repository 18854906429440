import React from 'react'

const Know = () => {
  const cubedMeters = 'm\u00B3';
  return (
    <section className="bottom-container">
      <div className="col-12">
        <h2 className="section-title">知っておくべきこと / 補足</h2>
        <h4 className="mono-type mono-type__geometry mt-5">コンクリートの購入に関して</h4>
        <div className="know-list__wrapper">
          <p className="know-list">∙ 常に必要な量より多めにコンクリートを購入してください。</p>
        </div>
        <h4 className="mono-type mono-type__geometry mt-5">計算式</h4>
        <div className="know-list__wrapper">
          <p className="know-list">∙  硬化コンクリートの重さは2,406.53 kg / {cubedMeters}です。</p>
          <p className="know-list">∙ 25 kgのコンクリートの袋で{cubedMeters}の硬化コンクリートが作れます。</p>
        </div>
      </div>
    </section>
  )
}

export default Know