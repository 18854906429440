export let canCalculate = false;

export function calculateSlab(height, length, width) {
    return (height * length * width).toFixed(2);
}

export function calculateCylinder(depth, height) {
    return (Math.pow((depth / 2), 2) * height * Math.PI).toFixed(2);
}

export function calculateTube(innerDepth, height, outerDepth) {
    let innerDepthVol = (Math.pow((innerDepth / 2), 2) * height * Math.PI);
    let outerDepthVol = (Math.pow((outerDepth / 2), 2) * height * Math.PI);
    return (outerDepthVol - innerDepthVol).toFixed(2);
}

export function calculateCurb(curbDepth, gutterWidth, curbHeight, flagThickness, length) {
    let vol1 = (curbDepth * length * (curbHeight + flagThickness));
    let vol2 = (length * gutterWidth * flagThickness);
    return (vol1 + vol2).toFixed(2);
}

export function calculateStairs(platformDepth, run, rise, width, numSteps) {
    let count = 0;
    for (let i = 0; i < numSteps.length; i++) {
        count += (platformDepth * width * (numSteps * rise) + run * width * rise * i);
    }
    return (count.toFixed(2));
}

export function calculateWeight(volume, units) {
    if (units === 'mm\u00B3') {
        return (volume / 0.0000024).toFixed(2); //returns pounds
    } else if (units === 'cm\u00B3') {
        return (volume * 0.002).toFixed(2); //returns pounds
    } else if (units === 'm\u00B3') {
        return (volume * 2,406.53).toFixed(2); //returns poundsnpm
    }
}

export function calculate25Bags(volume, units) {
    if (units === 'mm\u00B3') {
        return (volume / 1728 / .45).toFixed(2); //returns pounds
    } else if (units === 'cm\u00B3') {
        return (volume / .45).toFixed(2); //returns pounds
    } else if (units === 'm\u00B3') {
        return (volume * 27 / .45).toFixed(2); //returns poundsnpm
    }
}

export function commaSeparate(val) {
    return (val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
}