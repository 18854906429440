import React from "react"

const Privacy = () => {
    return (
        <main className="privacy-policy">
            <div className="container">
                <div className="row justify-content-center mt-5">
                    <div className="col-8 mb-5">
                        <h3 className="initials">法的</h3>
                        <h2 className="title">プライバシーポリシー</h2>
                        <p className="mt-5">個人情報保護について<a href="http://www.concretecalc.net" className="definition-link">http://www.concretecalc.jp</a>（以下「当社」といいます。）は、個人の人格尊重の理念のもとに、関係法令等を遵守し、実施するあらゆる事業において、個人情報を慎重に取り扱うために以下の個人情報保護方針（以下「本方針」といいます。）を定め、当社のユーザーその他関係者（以下「ユーザー等」といいます。）に関する情報（以下「ユーザー等情報」といいます。）を本方針にしたがい取り扱います。</p>
                        <h3 className="privacy-header">1.ユーザー等情報の取得</h3>
                        <p>当社は、以下に定めるとおり、個人情報（個人情報保護法第２条第１項により定義された「個人情報」をいい、以下同様とします。）を含むユーザー等情報を適法かつ適正な方法で取得します。</p>
                        <p>（１）ユーザー等にご提供頂く情報当社は、ユーザー等が所属する会社名・団体名、氏名、メールアドレス、その他当社のサービス提供のための必要な情報を取得します。</p>
                        <p>（２）当社サイトのご利用時に当社が収集する情報例）端末情報、行動履歴、位置情報、Cookie等</p>
                        <h3 className="privacy-header">2.ユーザー等情報の利用</h3>
                        <p>当社は、ユーザー等情報を、次の各号に定める目的の範囲内で、適正に取り扱います。</p>
                        <p>（１）当社のサービスに関するご案内、お問合せ等へ対応のため</p>
                        <p>（２）広告の配信、表示及び効果測定のため</p>
                        <p>（３）ユーザー等のトラフィック測定及び行動測定のため</p>
                        <p>（４）当社のサービス内容や本方針の変更などを通知するため</p>
                        <h3 className="privacy-header">3.ユーザー等情報の第三者提供</h3>
                        <p>当社は、あらかじめ明示した範囲及び法令等の規定に基づく場合を除いて、ユーザー等情報を事前に本人の同意を得ることなく外部に提供しません。</p>
                        <h3 className="privacy-header">4.情報収集モジュールの利用について</h3>
                        <p>当サイトには、利用状況及び広告効果等の情報を解析するため、当社が選定する以下の情報収集モジュールが組み込まれています。これに伴い、当社は、以下の情報収集モジュールの提供者（日本国外にある者を含みます。）に対しユーザー情報の提供を行う場合があります。これらの情報収集モジュールは、個人を特定する情報を含むことなくユーザー情報を収集し、収集された情報は、各情報収集モジュール提供者の定めるプライバシーポリシーその他の規定に基づき管理されます。</p>
                        <p>１）名称　：Google Analytics<br />提供者：Google Inc.<br />提供者による情報開示：<br /><a href="https://policies.google.com/technologies/partner-sites?hl=ja" className="definition-link">https://policies.google.com/technologies/partner-sites?hl=ja</a></p>
                        <h3 className="privacy-header">5.ユーザー等情報の管理</h3>
                        <p>当社は、ユーザー等情報を正確な状態に保つとともに、漏えい、滅失、き損などを防止するため、適切な措置を講じます。</p>
                        <h3 className="privacy-header">6.個人データの開示・訂正・追加・削除・利用停止について</h3>
                        <p>当社は、ユーザー等から、個人情報保護法の規定に基づき、自己の個人データについて利用目的の通知・開示・訂正・追加・削除・利用停止等（以下「開示等」といいます。）の申し出があった場合には、開示等を求める権利を有している方であることを確認したうえで、遅滞なく対応します。ただし、個人情報保護法その他の法令により、当社が開示等の義務を負わない場合は、この限りではありません。</p>
                        <h3 className="privacy-header">7.苦情・相談その他のお問い合せ窓口</h3>
                        <p>当社の取り扱うユーザー等情報に関するご意見、ご質問、開示等の要求、苦情のお申出その他ユーザー等情報の取扱いに関するお問合せは、以下のお問合せ窓口へご連絡ください。</p>
                        <p>連絡先：<a href="mailto:reneto523@gmail.com" className="definition-link">reneto523[at]gmail.com</a></p>
                        <h3 className="privacy-header">8.個人情報保護管理体制</h3>
                        <p>当社は、個人情報を保護するために、個人情報保護規程を定め、適切な管理体制を講じるとともに、これを役職員に周知徹底し、個人情報保護に関する意識啓発に努め、確実に実施します。</p>
                        <h3 className="privacy-header">9.本方針の変更・更新</h3>
                        <p>（１）当社は以下の場合に、当社が必要と認めた場合は、本方針を変更することがあります。</p>
                        <p>ア、本方針の変更が、ユーザー等の一般の利益に適合するときイ、本方針の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき</p>
                        <p>（２）前号の場合、当社は、変更後の本方針の効力発生日の２週間前までに、本方針を変更する旨及び変更後の本方針の内容とその効力発生日を当社サイト上に掲示し、又はユーザー等に電子メール等の電磁的記録方法で通知します。</p>
                        <p>（３）前二号に定めるほか、当社は、当社が定めた方法でユーザー等の同意を得ることにより、本方針を変更することがあります。この場合、変更後の本方針は、当社所定の方法で変更に同意したユーザー等に対してのみ適用されるものとします。</p>
                        <p>コンクリート計算機は、プロジェクトに必要なコンクリートの量を把握する為に作成されたサイトになります。</p>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Privacy