import React from 'react';
import Slab from './slab.jsx';
import Cylinder from './cylinder.jsx';
import Tube from './tube.jsx';
import Curb from './curb.jsx';
import Stair from './stair.jsx';
import Empty from './empty.jsx';
import { useGlobal } from '../../state/context'

const Fields = () => {
    const state = useGlobal();

    switch (state.category) {
        case '長方形':
            return <Slab />;
        case '円筒':
            return <Cylinder />;
        case '管':
            return <Tube />;
        case '縁石':
            return <Curb />;
        case '階段':
            return <Stair />;
        default:
            return <Empty />;
    }

}

export default Fields