import React from 'react'
import { useCalculations } from '../state/calculationsContext'

const Results = () => {
  const state = useCalculations();

  return (
    <div className="results-container" ref={state.resultsRef}>
      <div className="results">
        <span className="results-gray">Total weight: </span>
        <span className="results-value">{`${state.weightNeeded} kg`}</span>
      </div>
      <div className="results">
        <span className="results-gray">Total volume: </span>
        <span className="results-value">{`${state.totalVolume} ${state.unitPost}`}</span>
      </div>
      <div className="mt-5 results">
        <span className="results-gray">Using </span>
        <span className="results-value__plain">25 kg </span>
        <span className="results-gray">bags: </span>
        <span className="results-value">{state.sixtyBags}</span>
        <span className="results-value"> bags needed</span>
      </div>
      <div className={`mt-5 results ${state.displayResults}`}>
        <span className="results-gray">Using average prices, this will cost around:</span>
        <div>
          <span className="results-value">${state.priceEstimate60}</span>
          <span className="results-gray"> using </span>
          <span className="results-value__plain">25 kg </span>
          <span className="results-gray">bags </span>
        </div>
      </div>
    </div>
  )
}

export default Results