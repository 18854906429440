import React from 'react'

const Button = (props) => {
  return (
    <div className={props.buttonClass} onClick={props.handleSelect}>
      <h3 className='category-button__title' >{props.optionName}</h3>
      <img src={props.optionImg} alt={props.value} className='category-image' />
    </div>
  )
}

export default Button