import React from "react"
import fbLogo from "../images/fb-icon.svg"
import twitterLogo from "../images/twitter-icon.svg"
import linkedinLogo from "../images/linkedin-icon.svg"

const Footer = () => (
  <section className="footer-container justify-content-between d-flex flex-wrap">
    <div className="col-12 col-lg-5 mb-md-4 mb-lg-0">
      <h5 className="footer-title"><a href="/" className="footer-title--link">コンクリート計算機</a></h5>
      <a href="/privacy" className="definition-link">© 2022 プライバシーポリシー</a>
      <p className="footer-p mt-3">コンクリート計算機は、プロジェクトに必要なコンクリートの量を把握する為に作成されたサイトになります。</p>
    </div>
    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-md-0">
      <div className="footer-initials initials-about">パートナーサイト</div>
      <a href="https://archdictionary.com" target="_blank" rel="noreferrer" className="footer-link2">Architecture Dictionary</a>
    </div>
    <div className="col-6 col-md-3 col-lg-1">
      <div className="footer-initials">コンタクト</div>
      <div>
        <a href="mailto: reneto523@gmail.com" className="footer-link" target="_blank" rel="noreferrer">メール</a>
      </div>
      <div>
        <a href="https://twitter.com/reneto523" className="footer-link" target="_blank" rel="noreferrer">ツイッター</a>
      </div>
    </div>
    <div className="col-6 col-md-3 col-lg-1">
      <div className="footer-initials">シェア</div>
      <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fconcretecalc.jp%2F&src=sdkpreparse">
        <img src={fbLogo} className="mr-3 mr-md-1" alt="Facebook logo" />
      </a>
      <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fconcretecalc.jp&text=コンクリートを流し込む際に必要なセメント袋の購入量や使用料を計算します。.">
        <img src={twitterLogo} className="ml-3 mr-3 ml-md-1 mr-md-1" alt="Twitter logo" />
      </a>
      <a href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fconcretecalc.jp">
        <img src={linkedinLogo} className="ml-3 ml-md-1" alt="LinkedIn logo" />
      </a>
    </div>
  </section>
)

export default Footer
