import React from 'react'
import { useGlobal } from '../../state/context'
import rectangle from '../../images/enlarged/enlarged-rectangle.svg'
import cylinder from '../../images/enlarged/enlarged-cylinder.svg'
import tube from '../../images/enlarged/enlarged-tube.svg'
import curb from '../../images/enlarged/enlarged-curb.svg'
import stairs from '../../images/enlarged/enlarged-stairs.svg'

const Enlarged = () => {
  const state = useGlobal();
  switch (state.selectedCard) {
    case 'rectangle':
      return <img src={rectangle} className="enlarged-img" alt="rectangular" />;
    case 'cylinder':
      return <img src={cylinder} className="enlarged-img" alt="cylinder" />;
    case 'tube':
      return <img src={tube} className="enlarged-img" alt="tube" />;
    case 'curb':
      return <img src={curb} className="enlarged-img" alt="curb" />;
    case 'stairs':
      return <img src={stairs} className="enlarged-img" alt="stairs" />;
    default:
      return null
  }
}

export default Enlarged