import React from 'react'
import { useCalculations } from '../state/calculationsContext'

const Error = () => {
  const state = useCalculations();

  return (
    <div className="results-container" ref={state.resultsRef}>
      <div className="results results-gray">
        Please check your calculations again
      </div>
    </div>
  )
}

export default Error