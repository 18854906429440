import React from 'react';
import { useCalculations } from '../../state/calculationsContext';

const Input = (props) => {
    const state = useCalculations();
    return (
        <input type="number" className="input" min="0" id={props.parameter} onChange={state.handleInputs} />
    )
}

export default Input