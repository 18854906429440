import React from 'react';
import Input from './input'
import Quantity from './quantity'
import Units from './units'

const Cylinder = () => {
    return (
        <>
            <Units />
            <label>直径</label>
            <Input
                parameter={"depth"}
            />
            <label>高さ</label>
            <Input
                parameter={"height"}
            />
            <Quantity />
        </>
    )
}

export default Cylinder