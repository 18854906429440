import React, { useContext, useState, useRef } from 'react'
import {
  calculateCurb,
  calculateCylinder,
  calculateSlab,
  calculateStairs,
  calculateTube,
  calculateWeight,
  calculate25Bags,
  commaSeparate
} from '../components/geometry/calculations/calculations'
import { useGlobal } from './context'


export const CalculationsContext = React.createContext({});

export function CalculationsProvider({ children }) {

  const state = useGlobal();
  const resultsRef = useRef();

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [depth, setDepth] = useState(0);
  const [innerDepth, setInnerDepth] = useState(0);
  const [outerDepth, setOuterDepth] = useState(0);
  const [curbDepth, setCurbDepth] = useState(0);
  const [gutterWidth, setGutterWidth] = useState(0);
  const [curbHeight, setCurbHeight] = useState(0);
  const [flagThickness, setFlagThickenss] = useState(0);
  const [platformDepth, setPlatformDepth] = useState(0);
  const [run, setRun] = useState(0);
  const [rise, setRise] = useState(0);
  const [numSteps, setNumSteps] = useState(0);

  const [modifier, setModifier] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [totalVolume, setTotalVolume] = useState(0);
  const [unitVolume, setUnitVolume] = useState('mm\u00B3');
  const [unitPost, setUnitPost] = useState(unitVolume);
  const [weightNeeded, setWeightNeeded] = useState(0);
  const [twentyfiveBags, setTwentyfiveBags] = useState(0);

  const [displayResults, setDisplayResults] = useState('d-none');
  const [displayError, setDisplayError] = useState('false');
  const [priceEstimate25, setPriceEstimate25] = useState(0);
  const [showResults, setShowResults] = useState('d-none');
  const [hideEnlarged, setHideEnlarged] = useState(false);

  const inputValues = {
    height: height,
    width: width,
    length: length,
    depth: depth,
    innerDepth: innerDepth,
    outerDepth: outerDepth,
    curbDepth: curbDepth,
    gutterWidth: gutterWidth,
    curbHeight: curbHeight,
    flagThickness: flagThickness,
    platformDepth: platformDepth,
    run: run,
    rise: rise,
    numSteps: numSteps,
    quantity: quantity
  }

  const handleUnits = (e) => {
    let units = (e.target.value);
    if (units === 'mm') {
      setUnitVolume('mm\u00B3');
      setModifier(1);
    } else if (units === 'cm') {
      setUnitVolume('cm\u00B3')
      setModifier(100);
    } else if (units === 'm') {
      setUnitVolume('m\u00B3')
      setModifier(1000);
    }
  }
  const handleQuantity = (e) => {
    setQuantity(e.target.value);
  }
  const handleInputs = (e) => {
    let value = e.target.value
    switch (e.target.id) {
      case 'height':
        return setHeight(parseFloat(value));
      case 'width':
        return setWidth(parseFloat(value))
      case 'length':
        return setLength(parseFloat(value));
      case 'depth':
        return setDepth(parseFloat(value));
      case 'innerDepth':
        return setInnerDepth(parseFloat(value));
      case 'outerDepth':
        return setOuterDepth(parseFloat(value));
      case 'curbDepth':
        return setCurbDepth(parseFloat(value));
      case 'gutterWidth':
        return setGutterWidth(parseFloat(value));
      case 'curbHeight':
        return setCurbHeight(parseFloat(value));
      case 'flagThickness':
        return setFlagThickenss(parseFloat(value));
      case 'platformDepth':
        return setPlatformDepth(parseFloat(value));
      case 'run':
        return setRun(parseFloat(value));
      case 'rise':
        return setRise(parseFloat(value));
      case 'numSteps':
        return setNumSteps(parseFloat(value));
      default:
        return null
    }
  }

  const handleCalculate = () => {
    setUnitPost(unitVolume);
    setShowResults('d-block');
    setHideEnlarged(true);
    state.setBooleanValue(false);
    let val = 0;
    if (state.category === '長方形') {
      val = calculateSlab(height, length, width);
      updateResults(val, unitVolume);
    } else if (state.category === '縁石') {
      val = calculateCurb(curbDepth, gutterWidth, curbHeight, flagThickness, length);
      updateResults(val, unitVolume);
    } else if (state.category === '円筒') {
      val = calculateCylinder(depth, height);
      updateResults(val, unitVolume);
    } else if (state.category === '管') {
      val = calculateTube(innerDepth, height, outerDepth);
      updateResults(val, unitVolume);
    } else if (state.category === '階段') {
      val = calculateStairs(platformDepth, run, rise, width, numSteps);
      updateResults(val, unitVolume);
    }
    resultsRef.current.scrollIntoView({ behavior: 'smooth' })
    if (val === 0) {
      setDisplayResults('d-none');
      setDisplayError('true');
    } else if (val !== 0) {
      setDisplayResults('d-block');
      setShowResults('d-block');
      setDisplayError('false');
    }
    if (val < 0) {
      setDisplayResults('d-none');
      setDisplayError('true');
    } else if (val > 0) {
      setDisplayResults('d-block');
      setDisplayError('false');
    }
  }

  function updateResults(val, unitVolume) {
    let weight = calculateWeight(val, unitVolume);
    let twentyfiveBags = calculate25Bags(val, unitVolume);
    setTotalVolume(commaSeparate(val * quantity));
    setWeightNeeded(commaSeparate(weight));
    setTwentyfiveBags(commaSeparate(twentyfiveBags));
    setPriceEstimate25(commaSeparate((Math.ceil(twentyfiveBags) * 4.5).toFixed(2)));
  }

  const calculationsFunctions = {
    inputValues: inputValues,
    handleCalculate: handleCalculate,
    handleUnits: handleUnits,
    handleQuantity: handleQuantity,
    handleInputs: handleInputs,
    displayResults: displayResults,
    totalVolume: totalVolume,
    unitVolume: unitVolume,
    unitPost: unitPost,
    modifier: modifier,
    quantity: quantity,
    weightNeeded: weightNeeded,
    twentyfiveBags: twentyfiveBags,
    priceEstimate25: priceEstimate25,
    resultsRef: resultsRef,
    displayError: displayError,
    showResults: showResults,
    hideEnlarged: hideEnlarged,
  }

  return (
    <CalculationsContext.Provider value={calculationsFunctions}>
      {children}
    </CalculationsContext.Provider>
  )
}

export const useCalculations = () => useContext(CalculationsContext);