
import "./styles/fonts.css"
import "./styles/layout.scss"
import "./styles/inputs.scss"
import 'bootstrap/dist/css/bootstrap.css'
import Header from './components/header/header'
import Geometry from './components/geometry/geometry'
import Inputs from './components/inputs/inputs'
import Know from './components/geometry/know'
import Footer from './components/footer'
import Privacy from "./components/privacy"
import { GlobalProvider } from './state/context'
import { CalculationsProvider } from './state/calculationsContext'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <GlobalProvider>
            <CalculationsProvider>
              <Header />
              <Geometry />
              <Inputs />
              <Know />
              <Footer />
            </CalculationsProvider>
          </GlobalProvider>
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
