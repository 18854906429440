import React from "react"

const Header = () => (
    <header className="header-container d-flex justify-content-between">
        <div className="col-12">
            <h1 className="title">
            コンクリート計算機
            </h1>
            <h2 className="subtitle col-md-8 col-lg-6">
            コンクリートを流し込む際に必要なセメント袋の購入量や使用料を計算します。
            </h2>
        </div>
    </header >
)


export default Header