import React from 'react';
import Input from './input'
import Quantity from './quantity'
import Units from './units'

const Curb = () => {
    return (
        <>
            <Units />
            <label>縁石の深さ</label>
            <Input
                parameter={"curbDepth"}
            />
            <label>縁石の高さ</label>
            <Input
                parameter={"curbHeight"}
            />
            <label>溝の幅</label>
            <Input
                parameter={"gutterWidth"}
            />
            <label>平板の厚み</label>
            <Input
                parameter={"flagThickness"}
            />
            <label>奥行き</label>
            <Input
                parameter={"length"}
            />
            <Quantity />
        </>
    )
}

export default Curb