import React from 'react'
import Categories from './categories'

const Geometry = () => {
  return (
    <section className="content-container">
      <div className="col-12">
        <h2 className="section-title">どの形状で計算しますか？</h2>
        <h4 className="mono-type mono-type__geometry">形状をお選びください</h4>
        <Categories />
      </div>
    </section>
  )
}

export default Geometry