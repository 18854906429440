import React from 'react';
import Units from './units'
import Quantity from './quantity'

const Empty = () => {
  return (
    <>
      <Units />
      <Quantity />
    </>
  )
}

export default Empty