import React, { useContext, useState } from 'react'
import rectangularImg from '../images/rectangular.svg'
import cylinderImg from '../images/cylinder.svg'
import tubeImg from '../images/tube.svg'
import curbImg from '../images/curb.svg'
import stairsImg from '../images/stairs.svg'

export const GlobalContext = React.createContext({});

export function GlobalProvider({ children }) {
  let buttonInactive = 'category-button__inactive category-button';
  let buttonActive = 'category-button__active category-button';

  const [slabButton, setSlabButton] = useState(buttonInactive);
  const [cylinderButton, setCylinderButton] = useState(buttonInactive);
  const [tubeButton, setTubeButton] = useState(buttonInactive);
  const [curbButton, setCurbButton] = useState(buttonInactive);
  const [stairsButton, setStairsButton] = useState(buttonInactive);
  const [selectedCard, setSelectedCard] = useState('');

  const [boolean, setBoolean] = useState(true);

  const options = {
    rectangular: {
      name: '長方形',
      img: rectangularImg,
      button: slabButton
    },
    cylinder: {
      name: '円筒',
      img: cylinderImg,
      button: cylinderButton
    },
    tube: {
      name: '管',
      img: tubeImg,
      button: tubeButton
    },
    curb: {
      name: '縁石',
      img: curbImg,
      button: curbButton
    },
    stairs: {
      name: '階段',
      img: stairsImg,
      button: stairsButton
    },
  }

  const [state, setState] = useState('');

  const handleSelect = (e) => {
    let clickedCategory = e.currentTarget.children[0].innerText;
    setState(clickedCategory);
    setBoolean(true);
    resetButton();
    if (clickedCategory === '長方形') {
      setSlabButton(buttonActive);
      setSelectedCard('長方形');
    } else if (clickedCategory === '円筒') {
      setCylinderButton(buttonActive)
      setSelectedCard('円筒');
    } else if (clickedCategory === '管') {
      setTubeButton(buttonActive);
      setSelectedCard('管');
    } else if (clickedCategory === '縁石') {
      setCurbButton(buttonActive);
      setSelectedCard('縁石');
    } else if (clickedCategory === '階段') {
      setStairsButton(buttonActive);
      setSelectedCard('階段');
    }
  }

  const resetButton = () => {
    setSlabButton(buttonInactive);
    setCylinderButton(buttonInactive);
    setTubeButton(buttonInactive);
    setCurbButton(buttonInactive);
    setStairsButton(buttonInactive);
  }

  const setBooleanValue = (e) => {
    setBoolean(e);
  }

  const stateFunctions = {
    category: state,
    handleSelect: handleSelect,
    options: options,
    selectedCard: selectedCard,
    boolean: boolean,
    setBooleanValue: setBooleanValue
  }

  return (
    <GlobalContext.Provider value={stateFunctions}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobal = () => useContext(GlobalContext);