import React from 'react'
import { useCalculations } from '../../state/calculationsContext'

const Units = () => {
    const state = useCalculations();
    return (
        <>
            <label className="input-label--unfocused">単位</label>
            <select className="input-field input" id="units" onChange={state.handleUnits}>
                <option className="helvetica">mm</option>
                <option className="helvetica">cm</option>
                <option className="helvetica">m</option>
            </select>
        </>
    )
}

export default Units