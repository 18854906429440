import React from 'react'
import { useCalculations } from '../../state/calculationsContext'

const Calculate = () => {
  const state = useCalculations();
  return (
    <button className="calculate-btn" onClick={state.handleCalculate}>計算</button>
  )
}

export default Calculate